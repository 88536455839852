import '@popperjs/core';
import 'bootstrap';
import 'htmx.org';
import 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chart.js-plugin-labels-dv';
import 'chartjs-adapter-date-fns';


import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
